import React, { useState,useContext, useEffect } from 'react';


import Logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheck, faSquare,  faEye, faEyeSlash, } from '@fortawesome/free-regular-svg-icons';



import { Link, useNavigate, useParams } from 'react-router-dom'

import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GHeader from '../partials/gHeader';
import GInput from '../components/gInput';
function ResetUser() {
    const navigate = useNavigate();
    const { profile , setProfile, setToken} = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [showpass, setShowPass] =useState(false);
    const [checkrem, setChechrem] =useState(false);

    const [password, setPassword]=useState('');
    const [vpassword, setVPassword]=useState('');
    const [email, setEmail]=useState('');
    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);
    const {resetkey} = useParams(); 
    const [isSuccess, setIsSuccess] = useState(false);

    /* const onLogin=()=>{
        navigate('/dashboard');
    } */
    const onLogin=async(e)=>{
      e.preventDefault(); 
      if(password!=vpassword){
        setErr(Constants.vPAssErr);
        return;
      }else{
        setErr('');
      }

        setLoading(true);
        
        try {
          var v={username:email, password};
          const resp = await fetch(`${Constants.rooturl}/game/passwordreset/${resetkey}`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': '',
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              //setGazettes(data.gazettes);
              setIsSuccess(true);
          }else if(data.code == -1) {   
            setErr('The email or reset key not correct. Please follow the link from your email and Try again');
          }
          else if(data.code == -2) {   
            setErr('The reset key has expired. Goto to login to do forget password to generate a new one.');
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
         // onMove();
        }

       }


  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
          <GHeader active="reset" />
       <div style={{display:'flex'}}>
            <div style={{width:500, maxWidth:'80%', margin:'auto'}}>
            {!isSuccess &&     <form onSubmit={onLogin}>
                    <div className='idtab'>
                    
                    <div  className='tabitem tabactive'><div><span>Set New Password</span> </div> </div>
                        </div>
                    <GInput label="Email" value={email} onChange={(e)=>setEmail(e.target.value)}  required />
                        
                    <GInput label="Password" type={showpass?'text':"password"} value={password} onChange={(e)=>setPassword(e.target.value)}
                    rightIcon={<FontAwesomeIcon icon={showpass?faEye:faEyeSlash } onClick={()=>setShowPass(!showpass)} />} minlength={5} required />

                    <GInput label="Verify Password" type={showpass?'text':"password"} value={vpassword} onChange={(e)=>setVPassword(e.target.value)}
                    rightIcon={<FontAwesomeIcon icon={showpass?faEye:faEyeSlash }  onClick={()=>setShowPass(!showpass)} />} required />

                    
                    {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                    {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                       <div style={{marginTop:16, marginBottom:16}}>
                        <button className='greenbutton' style={{width:'100%'}} type="submit">Set New Password</button>
                        </div>         
                        
                              </div>} 
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
                    <div><span>You want to login without reset credentials? <Link to={'/login'}> Login</Link></span></div>
                </form>}
                {isSuccess && <div style={{textAlign:'center'}}>
                    <h1>Reset Successful</h1>
                    <h3>You can login with the new credentials.</h3>
                    <div style={{marginTop:16}}>
                   <h3><Link to={'/'}> Home</Link> | <Link to={'/login'}> Login</Link></h3> 
                    </div>
                    </div>}   
            </div>
       </div>  
      
        </div>
      
    </div>
  )
}

export default ResetUser